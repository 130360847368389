import { FormError } from 'bloko/blocks/form';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { Verification } from 'src/models/applicant/auth';

import TimeoutCounter from 'src/components/OTP/TimeoutCounter';

const CONFIRM_CODE_BLOCKED = 'CONFIRM_CODE_BLOCKED';
const CONFIRM_CODE_BLOCKED_PHONE = 'CONFIRM_CODE_BLOCKED_PHONE';

const TrlKeys = {
    default: 'account.connect.merge_by_code.email.error.unknown',
    CODE_NOT_FOUND: 'account.connect.merge_by_code.email.error.CODE_NOT_FOUND',
    [CONFIRM_CODE_BLOCKED]: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED',
    [CONFIRM_CODE_BLOCKED_PHONE]: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED.phone',
    WRONG_CODE: 'account.connect.merge_by_code.email.error.WRONG_CODE',
    CODE_EXPIRED: 'account.connect.merge_by_code.email.error.CODE_EXPIRED',
};

const getErrorText = ({ key, nextConfirmTime }: Verification, trls: LangTrls, authType: string) => {
    if (key === CONFIRM_CODE_BLOCKED) {
        const errorText =
            authType.toLowerCase() === 'email'
                ? trls[TrlKeys[CONFIRM_CODE_BLOCKED]]
                : trls[TrlKeys[CONFIRM_CODE_BLOCKED_PHONE]];
        return formatToReactComponent(errorText, {
            '{0}': <TimeoutCounter till={nextConfirmTime['@timestamp'] || nextConfirmTime.timestamp} />,
        });
    }
    return trls[TrlKeys[key as keyof typeof TrlKeys]] || trls[TrlKeys.default];
};

const CodeError: TranslatedComponent<{
    verification: Verification | null;
    authType?: string;
}> = ({ verification, authType = 'email', trls }) => {
    if (!verification) {
        return null;
    }

    const errorKey = verification.key;

    return (
        <FormError data-qa={`oauth-merge-by-code__code-error-${errorKey.toLowerCase()}`} show>
            {getErrorText(verification, trls, authType)}
        </FormError>
    );
};

export default translation(CodeError);
